import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LoaderWidget, PreferencesProvider, RootProvider } from '@jarvis/react-portal-addons';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { ThemeProvider } from '@veneer/theme';
import { getLDClientSideID, getUniqueUserKey } from './utils/ldClient';
import resources from './assets/locale/index';
import DEFAULT_PREFERENCES from './constants/defaultPreferences';
import App from './App';

const MainApp = ({
  children,
  localization,
  preferences,
}) => (
  <ThemeProvider>
    <RootProvider localization={localization} resources={resources}>
      <PreferencesProvider currentPreferences={preferences} defaultPreferences={DEFAULT_PREFERENCES}>
        {children}
      </PreferencesProvider>
    </RootProvider>
  </ThemeProvider>
);

MainApp.propTypes = {
  children: PropTypes.objectOf(PropTypes.any),
  localization: PropTypes.objectOf(PropTypes.any),
  preferences: PropTypes.objectOf(PropTypes.any),
};

MainApp.defaultProps = {
  children: {},
  localization: {},
  preferences: {},
};

const HelpCenter = props => {
  const [Container, setContainer] = useState(() => ({ children }) => children);
  const [fetchingLD, setFetchingLD] = useState(true);

  const localization = props.shell?.v1?.localization;
  const locale = localization?.locale;
  const { properties } = props;

  useEffect(() => {
    const fetchFetureFlags = async () => {
      try {
        const LDProvider = await asyncWithLDProvider({
          clientSideID: getLDClientSideID(),
          options: {
            bootstrap: 'localStorage',
            streaming: false,
          },
          reactOptions: {
            useCamelCaseFlagKeys: true,
          },
          user: {
            key: getUniqueUserKey(locale),
          },
        });
        setContainer(() => LDProvider);
      } catch (err) {
        console.error(err);
      } finally {
        setFetchingLD(false);
      }
    };
    fetchFetureFlags();
  }, [locale]);

  if (fetchingLD) {
    return <LoaderWidget fullScreen />;
  }

  return (
    <Container>
      <MainApp localization={localization} preferences={properties}>
        <App {...props} />
      </MainApp>
    </Container>
  );
};

HelpCenter.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any),
  shell: PropTypes.objectOf(PropTypes.any),
};

HelpCenter.defaultProps = {
  properties: {},
  shell: {},
};

export default HelpCenter;
